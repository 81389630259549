    
// @ts-nocheck

import request from '../utils/http'
type Serve<T, G> = (data?: T) => Promise<G>
export class IReqidGame {
  "phone": string;
  "tenantId"?: number | string;
}

export class IResidGame {
  "status": number;
  "message": string;
  "timestamp": number;
  "data": {
    "balance" : number;
  }
}
const http: Serve<IReqidGame, IResidGame['data']> = (data?) => request({
  method: 'POST',
  url: '/app/user/score/info',
  data: data
})
export default http