import { Button } from 'antd-mobile';
import styled from "styled-components";
import tabDefaultGame from '../../assets/tabDefaultGame.png'
import tabDefaultScore from '../../assets/tabDefaultScore.png'
import tabSelectGame from '../../assets/tabSelectGame.png'
import tabSelectScore from '../../assets/tabSelectScore.png'
import gameLevel from '../../assets/gameLevel.png'
import gamePeople from '../../assets/gamePeople.png'
import gameWin from '../../assets/gameWin.png'
import gameJun from '../../assets/gameJun.png'
import gameNbBili from '../../assets/gameNbBili.png'
import gameNbTime from '../../assets/gameNbTime.png'
import gameNbWin from '../../assets/gameNbWin.png'
import noData from '../../assets/noData.png'


export const TabsView = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0 ;
  .gameIcon {
    width: 22px;
    height: 22px;
    background: url(${tabDefaultGame});
    background-size: cover;
  }
  .gameSelectIcon {
    width: 22px;
    height: 22px;
    background: url(${tabSelectGame});
    background-size: cover;
  }
  .scoreIcon {
    width: 22px;
    height: 22px;
    background: url(${tabDefaultScore});
    background-size: cover;
  }
  .scoreSelectIcon {
    width: 22px;
    height: 22px;
    background: url(${tabSelectScore});
    background-size: cover;
  }

  .am-tab-bar-bar::before {
    display: none !important;
  }
`

export const GameListView = styled.div`
 width: 100%;
 height: 100%;
 background: #393B4E;
 box-sizing: border-box;
 padding: 20px 15px;
 position: relative;
 .am-list-body {
  background: #393B4E;

 }
 .am-list-body::before {
   display: none !important;
 }
 .am-list-body::after {
   display: none !important;
 }

 .nameTitle {
  font-size: 28px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
  margin-bottom: 30px;
 }
  .listview {
    background: #393B4E;

  }
  .listCustomItem {
    background: #42465B;
    border: 0px solid #0C0407;
    border-radius: 12px;
    margin-bottom: 20px;
    padding: 17px 18px 30px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 20px;
      font-weight: 400;
     color: #FFFFFF;
       line-height: 20px;
    }
    .itemDetail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 35px;
      >div:nth-of-type(1) {
        display: flex;

        .itemImgs {
          display: flex;
          align-items: center;
          .itemImg1{
            background: url(${gameWin});
            background-size: cover;
            width: 19px;
            height: 18px;
          }
          .itemImg2{
            margin-left:10px;
            background: url(${gameLevel});
            background-size: cover;
            width: 19px;
            height: 18px;
          }
          .itemImg3{
            margin-left:10px;
            background: url(${gamePeople});
            background-size: cover;
            width: 19px;
            height: 18px;
          }
          .itemImg4{
            margin-left:10px;
            background: url(${gameJun});
            background-size: cover;
            width: 19px;
            height: 18px;
          }
         .itemImgsText {
            margin-left: 6px;
            font-size: 16px;
            font-weight: 400;
            color: #87868B;
          }
        }

      }
   
      >div:nth-of-type(2) {
        width: 53px;
        height: 21px;
        background: #339966;
        border: 0px solid #0C0407;
        border-radius: 3px;
        color: #fff;
        text-align: center;
        font-size: 15px;
        line-height:21px;
      }

    }
    
  }

  .listFuture {
    background: #42465B;
    border: 0px solid #0C0407;
    border-radius: 12px;
    margin-bottom: 20px;
    padding: 15px 18px 12px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 20px;
      font-weight: 400;
     color: #FFFFFF;
       line-height: 20px;
    }
    .time {
      font-size: 18px;
      font-weight: 400;
      color: #FC9F00;
      line-height: 18px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .itemDetail {
      display: flex;
      align-items: center;
      .itemImgs {
          display: flex;
          align-items: center;
      .itemImg1{
            background: url(${gameNbWin});
            background-size: cover;
            width: 19px;
            height: 18px;
          }
          .itemImg2{
            margin-left:10px;
            background: url(${gameNbBili});
            background-size: cover;
            width: 19px;
            height: 18px;
          }
          .itemImg3{
            margin-left:10px;
            background: url(${gameNbTime});
            background-size: cover;
            width: 19px;
            height: 18px;
          }
         .itemImgsText {
            margin-left: 6px;
            font-size: 16px;
            font-weight: 400;
            color: #87868B;
          }
      }
      
    }
  }

  .section {
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 40px;
    margin-bottom: 28px;
  }

  .noData {
    width: 287px;
    height: 356px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    .img {
      width: 287px;
      height: 316px;
      background: url(${noData});
      background-size: cover;
    }

    .title {
      margin-top: 15px;
      font-size: 14px;
      font-weight: 400;
      color: #87868B;
    }
  }

`

export const ScoreView = styled.div`
 width: 100%;
 height: 100%;
 background: #393B4E;
 display: flex;
 flex-direction: column;
 align-items: center;
 position: relative;
 box-sizing: border-box;
 padding-top: 200px;
 .nameTitle {
   position: absolute;
   left: 15px;
   top: 20px;
   z-index: 2;
  font-size: 28px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
  margin-bottom: 30px;
 }
 .am-input-item {
  background: #393B4E;

 }
 .am-list-item .am-input-control input {
  color: #FFFFFF ;
  padding: 0;
  border-bottom: 1px solid #47495b;
  width: 80vw;
  font-size: 18px;
 }
 .button {
   margin-top: 100px;
   width: 80vw;
 }
 .am-button {
   background: #FC9F00;
   border: none !important;
   border-radius: 23.5px;
   color: #FFFFFF;
   border-width: 0px;
   line-height: 47px;
 }
 .am-button::before {
   border: none !important;
 }
 .am-list-line::after {
   display: none !important;
 }
`
