import { TabBar } from "antd-mobile";
import React, { useState } from "react";
import GameList from "./gameList";
import GameScore from "./gameScore";
import { TabsView } from "./styles";

const GameTabs = () => {
  const [select, setselect] = useState('gameList')
  return (
    <TabsView>
      <TabBar
        unselectedTintColor="#70717D"
        tintColor='#FAA001'
        barTintColor="#303241"
      >
        <TabBar.Item
          title="赛事"
          key="gameList"
          icon={<div className="gameIcon" />
          }
          selectedIcon={<div className="gameSelectIcon" />
          }
          selected={select === 'gameList'}
          // badge={1}
          onPress={() => {
            setselect('gameList')
          }}
          data-seed="logId"
        >
          <GameList />
        </TabBar.Item>
       
        <TabBar.Item
          icon={
            <div className="scoreIcon" />
          }
          selectedIcon={
            <div className="scoreSelectIcon"/>
          }
          title="积分"
          key="score"
          selected={select === 'score'}
          onPress={() => {
            setselect('score')
          }}
        >
          <GameScore />
        </TabBar.Item>
      </TabBar>
    </TabsView>
  );
}

export default GameTabs;