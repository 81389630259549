
// @ts-nocheck

import request from '../utils/http'
type Serve<T, G> = (data?: T) => Promise<G>
export class IReqidGame {
  "tenantId"?: number | string;
}

export class IResidGame {
  "status": number;
  "message": string;
  "timestamp": number;
  "data": {
    "startList": {
      "currentLevel": number;
      "totalChips": number;
      "name": string;
      "id": number | string;
      "currentUsers": number;
      "status": number;
    }[],
    "waitList": {
      "id": number | string;
      "name": string;
      "winnerTotal": number;
      "restDuration": number;
      "levelDuration": number;
    }[]

  }
}
const http: Serve<IReqidGame, IResidGame['data']> = (data?) => request({
  method: 'POST',
  url: '/app/match/home',
  data: data
})
export default http