import React, { useEffect, useState } from "react";
import {
  ListView,
  Toast,
} from 'antd-mobile'
import qs from 'query-string'
import API from '../../api/index'
import { GameListView } from "./styles";
const GameList = () => {
  const { query } = qs.parseUrl(window.location.href) as any;
  const [list, setList] = useState<any[]>([])
  useEffect(() => {
    getList();
  }, [])

  const getList = async () => {
    try {
      Toast.loading('加载中...', 60);
       const res = await API.idGamelist({tenantId: query.tenantId});
       let startList: any[] = res.startList.map((item) => {
         return {
           ...item,
           elementType: 2,
          }
       })
      if (startList?.length > 0) {
        startList = [{ elementType: 1, name: '正在进行' }, ...startList];
      }
       let waitList: any[] = res.waitList.map((item) => {
         return {
           ...item,
           elementType: 3
         }
       })
      if (waitList?.length > 0) {
        waitList = [{ elementType: 1, name: '待开始' }, ...waitList]

      }
       const list: any[] = [ ...startList, ...waitList];
       setList(list);
       Toast.hide();

     } catch (error) {

     }
  }
  const dataSource = new ListView.DataSource({
    rowHasChanged: (row1: any, row2: any) => row1 !== row2,
  })
  const getFooter = () => {
    return (
      <div style={{ textAlign: 'center', fontSize: '14px' }}>
        {JSON.stringify(list) === JSON.stringify([false])
          ? '暂无商品'
          : '已经到底了~'}
      </div>
    )
  }
  const getPingjun = (record: any) => {
    const currentUsers = isNaN(Number(record.currentUsers)) ? 1 : Number(record.currentUsers);
    const totalChips = isNaN(Number(record.totalChips)) ? 1 : Number(record.totalChips);
    const pingjun = (totalChips / (currentUsers || 1)).toFixed(0);
    return pingjun;
  }
  const row = (record: any) => {
    

    return (
      <div
        className="listCustomItem"
      >
        <div className="title">{ record.name }</div>
        <div className="itemDetail">
          <div>
            <div className="itemImgs"><div className="itemImg1"></div> <div className="itemImgsText">{ record.winnerTotal}</div> </div>
            <div className="itemImgs"><div className="itemImg2"></div> <div className="itemImgsText">{record.currentLevel }</div> </div>
            <div className="itemImgs"><div className="itemImg3"></div> <div className="itemImgsText">{record.currentUsers}</div> </div>
            <div className="itemImgs"><div className="itemImg4"></div> <div className="itemImgsText">{ getPingjun(record) }</div> </div>
          </div>

          <div>进行中</div>
        </div>
      </div>
    )
  }
  const futureGameRow = (record: any) => {
    return (
      <div
        className="listFuture"
      >
        <div className="title">{ record.name }</div>
        <div className="time">{record.startTime }</div>
        <div className="itemDetail">
          <div className="itemImgs"><div className="itemImg1"></div> <div className="itemImgsText">{record.winnerTotal }</div> </div>
          <div className="itemImgs"><div className="itemImg3"></div> <div className="itemImgsText">{record.levelDuration }</div> </div>
        </div>
      </div>
    )

  }
  const sectionRow = (record: any) => {
    return <div className="section" >{ record.name }</div>
  }

  const totalRow = (record: any) => {
    switch (record.elementType) {
      case 1:
        return sectionRow(record);
      case 2:
        return row(record);
      case 3:
        return futureGameRow(record); 
      default:
        return <div></div>
    }
  }
  return <GameListView>
    <div className="nameTitle">赛事中心</div>
    {
      list.length > 0 ?
      <ListView
        // ref={el => this.lv = el}
        className="listview"
        renderFooter={getFooter}
        dataSource={dataSource.cloneWithRows(list)}
        renderRow={totalRow}
        useBodyScroll
        initialListSize={10}
        pageSize={10}
  
      /> :
      <div className="noData">
        <div className="img"></div>
        <div className="title">暂无赛事</div>
      </div>

    }
    
  </GameListView>
}

export default GameList;