import axios from 'axios';
import { Toast } from 'antd-mobile';
import envConfig from '../envConfig'

const env: string = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development'
// @ts-ignore
const baseUrl = envConfig[env].api;
const loginHost = envConfig[env].loginHost;

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
  // headers: { 'Content-Type': 'multipart/form-data' },
  withCredentials: false, // 允许携带cookie
});

// 添加一个请求拦截器
instance.interceptors.request.use(
  request => {
    const token = localStorage.getItem('token') || '';
    if (process && process.env && process.env.REACT_APP_ENV !== 'mock') {
      request.headers['X-Token'] = token
    }
    for (const key in request.data) {
      if (request.data[key] === null || request.data[key] === '') {
        delete request.data[key]
      }
    }
    return request
  },
  err => Promise.reject(err)
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    const { status } = response.data
    if (status === 401) {
      // 未登录
      // window.location.replace(`${window.location.origin}/ui/login`)
      return Promise.reject(response.data.data)
    } else if (status === 200) {
      return Promise.resolve(response.data.data)
    } else {
      Toast.offline(response.data.message || `服务正忙，请稍后再试~ error:${status}`)
      return Promise.reject(response.data)
    }
  },
  err => {
    Toast.offline('服务出错，请刷新重试')
    return Promise.reject(err)
  }
);

export default instance
