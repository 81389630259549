
import idScoreSearch from './idScoreSearch'
import idGamelist from './idGamelist'


export default {
  idGamelist,
  idScoreSearch,
}

