export default {
  development: {
    // api: "http://yapi.ywfe.com/mock/187",
    api: "http://api.meimiaoshijie.top",
    loginHost: '',
    static: "/"
  },
  debug: {
    api: "http://api.meimiaoshijie.top",  // 联调
    loginHost: '',
    static: "/"
  },
  test: {
    api: "https://api.meimiaoshijie.top",
    loginHost: '',
    static: "/"
  },
  production: {
    api: 'https://api.meimiaoshijie.top',
    loginHost: '',
    static: '/',
  },
}

