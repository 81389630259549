import { Button, InputItem, Modal, Toast } from "antd-mobile";
import React, { useRef, useState } from "react"
import { ScoreView } from "./styles";
import API from '../../api/index'
import qs from 'query-string'

const GameScore = () => {
  const { query } = qs.parseUrl(window.location.href) as any;
  const iRef = useRef<{ phoneInput: any }>({ phoneInput: null });
  const userRef = useRef<{ phone: any }>({ phone: '' })
  const [balance, setbalance] = useState<any>('N/A')
  const [visible, setvisible] = useState(false);
  const toggle = () => {
    setvisible(!visible);
  }
  const changeName = (name: string) => {
    userRef.current.phone = name;
  }
  const searchAction = async () => {
    Toast.loading('查询中...', 60);
    try {
      const res = await API.idScoreSearch({ phone: userRef.current.phone, tenantId: query.tenantId });
      Toast.hide();
      setbalance(res.balance);
      toggle();
    } catch (error) {

    }
  }

  const toLocaleString = (value: any) => {
    if ((typeof value === 'number') || Number(value)) {
      return Number(value).toLocaleString();
    } else if (typeof value === 'string') {
      return value;
    } else {
      return '-'
    }
  }
  return <ScoreView>
    <div className="nameTitle">积分查询</div>

    <InputItem
      type='text'
      placeholder="输入手机号查询积分"
      className='item'
      style={{ height: 40 }}
      onChange={changeName}
      ref={(e: any) => {
        iRef.current.phoneInput = e;
      }}
      onClick={() => {
        iRef.current.phoneInput?.focus()
      }}
    />
    <Button className='button' onClick={searchAction}  >查 询</Button>
    <Modal
      visible={visible}
      transparent
      maskClosable={false}
      onClose={toggle}
      footer={[{ text: '返回', onPress: () => { toggle() } }]}
    >
      <div style={{ height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ color: '#FC9F00', fontSize: 40 }}>{toLocaleString(balance)}
        </div>
      </div>
    </Modal>
  </ScoreView>
}

export default GameScore